<template>
  <div class="blogpost ma-2">
    <v-row align="center">
      <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
        <v-btn text @click="$router.go(-1)">
          <v-icon left>mdi-arrow-left</v-icon>
          Zurück
        </v-btn>
      </v-col>
      <v-col cols="12" xs="12" sm="7" md="7" lg="8" xl="8">
        <h1>{{ blogpost.TITLE }}</h1>
      </v-col>
      <v-col cols="12" xs="12" sm="3" md="3" lg="2" xl="2">
        <v-rating
          :value="blogpost.RATING"
          color="primary"
          dense
          half-increments
          readonly
          size="25"
        ></v-rating>
      </v-col>
    </v-row>
    <v-container>
      <ComponentSelection></ComponentSelection>
    </v-container>

    <v-container>
      <v-divider></v-divider>
      <v-img height="200" :src="getImgUrl(blogpost.PICTURENAME)"></v-img>

      <span v-bind:element="n" v-for="n in getAmountOfElements()" :key="n">
        <div v-for="text in texts" :key="'text_' + text.ID">
          <div
            v-if="text.POSITION_ID == n"
            v-html="text.MESSAGE"
            class="mt-7"
          ></div>
        </div>

        <!-- https://prismjs.com/#languages-list -->
        <div v-for="code in codes" :key="'code_' + code.ID">
          <Prism
            v-if="code.POSITION_ID == n"
            :language="code.LANGUAGE"
            :code="code.DESCRIPTION"
          />
        </div>

        <div
          align="center"
          v-for="picture in pictures"
          :key="'picture_' + picture.ID"
        >
          <v-img
            class="ma-6"
            v-if="picture.POSITION_ID == n"
            contain
            width="80%"
            :src="getImgUrl(picture.NAME)"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>

        <div
          align="center"
          class="ma-6"
          v-for="video in videos"
          :key="'video' + video.ID"
        >
          <LazyYoutube
            v-if="video.POSITION_ID == n"
            maxWidth="100%"
            :src="video.LINK"
          />
        </div>
      </span>
    </v-container>

    <v-container align="center" justify="center">
      <CommentBox :postID="postID"></CommentBox>
    </v-container>
  </div>
</template>

<script>
import { UserLevelEnum } from "./../enums";

import ComponentSelection from "@/components/ComponentSelection.vue";

import Prism from "vue-prism-component";
import CommentBox from "@/components/Comments/CommentBox.vue";
import { LazyYoutube } from "vue-lazytube";

export default {
  data: () => ({
    blogpost: Object,
    texts: [],
    pictures: [],
    codes: [],
    videos: [],
    isActive: [],
  }),

  props: {
    postID: String,
  },

  mounted() {
    this.getBlogpost();
  },

  methods: {
    getImgUrl(pictureName) {
      if (typeof pictureName == "undefined") {
        return;
      }
      return require("@/assets/images/" + pictureName);
    },

    getAmountOfElements() {
      return (
        this.texts.length +
        this.pictures.length +
        this.codes.length +
        this.videos.length
      );
    },

    getBlogpost() {
      this.$store
        .dispatch("getBlogpost", {
          postID: this.postID,
        })
        .then((response) => {
          if (response.msg != null) {
            //TODO: Was tun wir wenn wir kein Element erhalten???
          } else {
            this.blogpost = response[0];
            if (this.blogpost.AMOUNTOFTEXTS != 0) {
              this.getTexts();
            }
            if (this.blogpost.AMOUNTOFPICTURES != 0) {
              this.getPictures();
            }
            if (this.blogpost.AMOUNTOFCODES != 0) {
              this.getCodes();
            }
            if (this.blogpost.AMOUNTOFVIDEOS != 0) {
              this.getVideos();
            }
          }
        });
    },

    getTexts() {
      this.$store
        .dispatch("getTexts", {
          postID: this.postID,
        })
        .then((response) => {
          if (response.msg != null) {
            //TODO: Was tun wir wenn wir kein Element erhalten???
          } else {
            this.texts = response;
          }
        });
    },

    getPictures() {
      this.$store
        .dispatch("getPictures", {
          postID: this.postID,
        })
        .then((response) => {
          if (response.msg != null) {
            //TODO: Was tun wir wenn wir kein Element erhalten???
          } else {
            this.pictures = response;
          }
        });
    },

    getCodes() {
      this.$store
        .dispatch("getCodes", {
          postID: this.postID,
        })
        .then((response) => {
          if (response.msg != null) {
            //TODO: Was tun wir wenn wir kein Element erhalten???
          } else {
            this.codes = response;
          }
        });
    },

    getVideos() {
      this.$store
        .dispatch("getVideos", {
          postID: this.postID,
        })
        .then((response) => {
          if (response.msg != null) {
            //TODO: Was tun wir wenn wir kein Element erhalten???
          } else {
            this.videos = response;
          }
        });
    },
  },

  computed: {
    editBlogPost() {
      return UserLevelEnum.Admin == this.$store.getters.currentUserLevel;
    },
  },

  components: {
    ComponentSelection,
    Prism,
    CommentBox,
    LazyYoutube,
  },
};
</script>

<style>
</style>