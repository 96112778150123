<template>
  <div class="my-4 py-2">
    <v-card elevation="6" shaped>
      <v-row>
        <v-col cols="5" xs="4" sm="4" md="2" lg="2" xl="2">
          <v-row class="py-3" align="center" justify="center">
            <v-avatar rounded :size="sizePicture()">
              <v-img :src="this.picture" :alt="getNickName()" />
            </v-avatar>
          </v-row>

          <v-tooltip v-model="show" top>
            <template v-slot:activator="{ on, attrs }">
              <v-row
                v-bind="attrs"
                v-on="on"
                align="center"
                justify="center"
                :style="'font-size:' + sizeFont(0) + 'px'"
                >{{ getNickName() }}</v-row
              >
            </template>
            <span>{{ getName() }}</span>
          </v-tooltip>

          <v-row
            align="center"
            justify="center"
            :style="'font-size:' + sizeFont(3) + 'px'"
            >{{ getDate }}</v-row
          >

          <v-row justify="center">
            <v-rating
              :class="{ flashing: showEdit }"
              v-model="comment.RATING"
              color="primary"
              dense
              half-increments
              :readonly="!showEdit"
              :size="sizeFont(-2)"
            ></v-rating>
          </v-row>
        </v-col>
        <v-col
          cols="4"
          xs="6"
          sm="6"
          md="9"
          lg="9"
          xl="9"
          :style="'font-size:' + sizeFont(-2) + 'px'"
        >
          <span class="panel-body">
            <div class="outputPanel" v-html="commentText"></div>
          </span>
          <span v-if="showEdit">
            <v-textarea
              label="Editor"
              auto-grow
              v-model="commentText"
              :style="
                getDescription != commentText
                  ? { 'background-color': '#FFF9C4' }
                  : null
              "
            ></v-textarea>
          </span>
          <v-row>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-btn icon v-if="showEdit" @click="saveComment()"
                ><v-icon>mdi-content-save</v-icon></v-btn
              >
            </v-col>
            <v-col
              cols="6"
              xs="6"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              class="text-right"
            >
              <v-btn
                icon
                v-if="showEditPossibility && showEdit"
                @click="deleteComment()"
                ><v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" xs="2" sm="2" md="1" lg="1" xl="1">
          <v-btn
            icon
            v-if="showEditPossibility && !showEdit"
            @click="showEdit = !showEdit"
            ><v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon v-if="showEdit" @click="showEdit = !showEdit"
            ><v-icon>mdi-close-thick</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <CommentAnswer
      v-for="comment in commentAnswers"
      :key="comment.ID"
      :comment="comment"
    ></CommentAnswer>
  </div>
</template>

<script>
import CommentAnswer from "@/components/Comments/CommentAnswer.vue";
import axios from "axios";

export default {
  components: {
    CommentAnswer,
  },

  data: () => ({
    show: false,
    user: Object,
    picture: "",
    commentText: "",
    showEditPossibility: false,
    showEdit: false,
    commentAnswers: [],
  }),

  props: {
    comment: Object,
    new: Boolean,
    blogpostid: String,
    ip: String,
  },

  mounted() {
    if (
      this.comment !== "undefined" &&
      this.comment.USER_EMAIL !== "undefined"
    ) {
      this.$store
        .dispatch("getUserInfo", {
          EMAIL: this.comment.USER_EMAIL,
        })
        .then((response) => {
          if (response.msg != null) {
            //TODO: Was tun wir wenn wir kein Element erhalten???
          } else {
            this.user = response[0];

            if (this.user.OWNPICTURE == true) {
              this.picture =
                axios.defaults.baseURL +
                "/images/avatars/" +
                this.user.EMAIL +
                ".jpg";
            } else {
              this.picture = require("@/assets/icon/black.svg");
            }
          }
        });

      if (
        this.comment.USER_EMAIL.localeCompare(this.currentUserEmail) == 0 &&
        this.currentUserEmail !== null
      ) {
        this.showEditPossibility = true;
      }

      this.commentText = this.getDescription;

      if (this.new) {
        this.showEdit = true;
      }
    }
  },

  computed: {
    getDate() {
      return this.getDateStringUTCFormat(new Date(this.comment.DATE));
    },

    getDescription() {
      return this.comment.DESCRIPTION;
    },

    currentUserEmail() {
      return this.$store.getters.currentUserEmail;
    },
  },

  methods: {
    getNickName() {
      return this.user.NICKNAME;
    },
    getName() {
      return this.user.FIRSTNAME + " " + this.user.LASTNAME;
    },

    saveComment() {
      if (this.new) {
        this.$store
          .dispatch("createComment", {
            blogpostid: this.blogpostid,
            rating: this.comment.RATING,
            description: this.commentText,
            ip: "1.1.1.1",
          })
          .then((response) => {
            if (response.msg != null) {
              //TODO: Sofern fehlgeschlagen show error
              this.showEdit = false;
            } else {
              // Sofern erfolgreich upgedated Kommentarfeld ausblenden
              this.showEdit = false;
              this.$emit("commentChanged", "true");
            }
          });
      } else {
        this.$store
          .dispatch("updateComment", {
            id: this.comment.ID,
            rating: this.comment.RATING,
            description: this.commentText,
            ip: "1.1.1.1",
          })
          .then((response) => {
            if (response.msg != null) {
              //TODO: Sofern fehlgeschlagen show error
              this.showEdit = false;
            } else {
              // Sofern erfolgreich upgedated Kommentarfeld ausblenden
              this.showEdit = false;
              this.$emit("commentChanged");
            }
          });
      }
    },

    deleteComment() {
      if (!this.new) {
        this.$store
          .dispatch("deleteComment", {
            id: this.comment.ID,
          })
          .then((response) => {
            if (response.msg != null) {
              //TODO: Sofern fehlgeschlagen show error
              this.showEdit = false;
            } else {
              // Sofern erfolgreich upgedated Kommentarfeld ausblenden
              this.showEdit = false;
              this.$emit("commentChanged", "true");
            }
          });
      }
    },

    sizePicture() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 40;
        case "sm":
          return 50;
        case "md":
          return 60;
        case "lg":
          return 70;
        case "xl":
          return 70;
        default:
          return 70;
      }
    },

    sizeFont(minusValue) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 9 - minusValue;
        case "sm":
          return 10 - minusValue;
        case "md":
          return 11 - minusValue;
        case "lg":
          return 12 - minusValue;
        case "xl":
          return 12 - minusValue;
        default:
          return 12 - minusValue;
      }
    },

    getDateStringUTCFormat(DATESTRING) {
      var date = new Date(DATESTRING);
      var mm = date.getMonth() + 1; // January = 0
      var dd = date.getDate();
      var h = date.getHours();
      var min = date.getMinutes();
      var sec = date.getSeconds();

      if (mm < 10) {
        mm = "0" + mm;
      }
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (h < 10) {
        h = "0" + h;
      }
      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }
      return (
        date.getFullYear() +
        "-" +
        mm +
        "-" +
        dd +
        " " +
        h +
        ":" +
        min +
        ":" +
        sec
      );
    },
  },
};
</script>

<style>
.flashing {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.4;
  }
}
</style>