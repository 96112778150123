<template>
  <v-container class="mt-8">
    <PopUpInfoForm
      :title="String(popUpInfo.title)"
      :description="String(popUpInfo.description)"
      v-model="popUpInfo.show"
    />
    <!-- color="primary lighten-1" -->
    <h1>
      Kommentare
      <span class="grey--text ml-4"
        ><v-icon color="primary">mdi-message</v-icon>
        {{ comments.length }}
      </span>
    </h1>
    <p>
      Sofern Ihnen der Artikel geholfen hat, oder Sie Probleme hatten mit diesem
      Artikel können Sie hier ein Kommentar zu jenem erstellen:
    </p>
    <v-card class="ma-1 pa-2" color="primary lighten-4">
      <div class="ma-2" align="center">
        <v-btn outlined text rounded @click="createNewComment">
          <v-icon>mdi-pencil</v-icon>
          Kommentar Erstellen
        </v-btn>
      </div>
      <Comment
        v-if="showNewComment"
        :comment="newComment"
        :new="true"
        :blogpostid="this.postID"
        @commentChanged="getComments"
      ></Comment>
      <Comment
        v-for="comment in comments"
        :key="comment.ID"
        :comment="comment"
        @commentChanged="getComments"
      ></Comment>
    </v-card>
  </v-container>
</template>

<script>
import Comment from "@/components/Comments/Comment.vue";
import PopUpInfoForm from "@/components/PopUpInfoForm.vue";

export default {
  components: {
    Comment,
    PopUpInfoForm,
  },

  data: () => ({
    comments: [],
    newComment: {
      DATE: Date,
      USER_EMAIL: String,
      DESCRIPTION: String,
      RATING: Number,
    },
    showNewComment: false,
    popUpInfo: {
      show: false,
      title: String,
      description: String,
    },
  }),

  props: {
    postID: String,
  },

  mounted() {
    this.getComments();
  },

  methods: {
    createNewComment() {
      if (this.loggedIn) {
        this.newComment.DATE = new Date();
        this.newComment.USER_EMAIL = localStorage.getItem("user_email");
        this.newComment.DESCRIPTION = "";
        this.newComment.RATING = 0;
        this.showNewComment = true;
      } else {
        this.popUpInfo.title = "Bitte Einloggen";
        this.popUpInfo.description =
          "Um ein Kommentar erfassen zu können müssen Sie sich eingeloggt haben. " +
          "Sollten Sie noch keinen Account haben erstellen Sie bitte einen um " +
          "die Funktionalität zu nutzen.";
        this.popUpInfo.show = true;
      }
    },

    getComments() {
      this.showNewComment = false;
      this.$store
        .dispatch("getComment", {
          postID: this.postID,
        })
        .then((response) => {
          if (response.msg != null) {
            //TODO: Was tun wir wenn wir kein Element erhalten???
          } else {
            this.comments = response;
          }
        });
    },
  },

  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>

<style>
</style>