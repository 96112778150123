<template>
  <v-row>
    <v-col class="mt-n2" cols="2" xs="2" sm="2" md="2" lg="1" xl="1"> </v-col>
    <v-col class="mt-n2" cols="10" xs="10" sm="10" md="10" lg="11" xl="11">
      <v-card elevation="3" shaped>
        <v-row>
          <v-col cols="6" xs="4" sm="4" md="2" lg="2" xl="2">
            <v-row align="center" justify="center">
              <v-avatar color="primary" rounded :size="sizePicture()">
                <v-img
                  :src="getAvatar()"
                  :alt="getNickName()"
                  @error="onImgError()"
                />
              </v-avatar>
            </v-row>

            <v-tooltip v-model="show" right>
              <template v-slot:activator="{ on, attrs }">
                <v-row
                  v-bind="attrs"
                  v-on="on"
                  align="center"
                  justify="center"
                  :style="'font-size:' + sizeFont(0) + 'px'"
                  >{{ getNickName() }}</v-row
                >
              </template>
              <span>{{ getName() }}</span>
            </v-tooltip>
            <v-row
              align="center"
              justify="center"
              :style="'font-size:' + sizeFont(3) + 'px'"
              >{{ getDate }}</v-row
            >
            <v-row align="center" justify="center">
              <v-rating
                :value="3"
                color="primary"
                dense
                half-increments
                readonly
                :size="sizeFont(-2)"
              ></v-rating>
            </v-row>
          </v-col>
          <v-col
            cols="6"
            xs="8"
            sm="8"
            md="10"
            lg="10"
            xl="10"
            :style="'font-size:' + sizeFont(-2) + 'px'"
          >
            <p>Wrote it wrong, ups</p>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    imgError: false,
  }),

  computed: {
    getDate() {
      return this.comment.DATE;
    },

    getDescription() {
      return this.comment.DESCRIPTION;
    },

    currentUser() {
      return this.$store.getters.currentUser;
    },
  },

  methods: {
    getNickName() {
      if (this.comment.USER_EMAIL != null) {
        return this.user.NICKNAME;
      } else {
        return this.comment.IP;
      }
    },
    getName() {
      if (this.comment.USER_EMAIL != null) {
        return this.user.FIRSTNAME + " " + this.user.LASTNAME;
      } else {
        return this.comment.IP;
      }
    },

    onImgError() {
      this.imgError = true;
    },

    getAvatar() {
      if (this.imgError) {
        return require("@/assets/icon/black.svg");
      } else {
        return (
          axios.defaults.baseURL +
          "/images/avatars/" +
          this.currentUser +
          ".jpg"
        );
      }
    },

    sizePicture() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 30;
        case "sm":
          return 40;
        case "md":
          return 50;
        case "lg":
          return 60;
        case "xl":
          return 60;
        default:
          return 60;
      }
    },

    sizeFont(minusValue) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 9 - minusValue;
        case "sm":
          return 10 - minusValue;
        case "md":
          return 11 - minusValue;
        case "lg":
          return 12 - minusValue;
        case "xl":
          return 12 - minusValue;
        default:
          return 12 - minusValue;
      }
    },
  },
};
</script>

<style>
</style>