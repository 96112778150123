<template>
  <v-select :items="items" label="Standard"></v-select>
</template>

<script>
import { BlogPostComponentsEnum } from "./../enums";
export default {
  data: () => ({
    items: [],
  }),

  mounted() {
    this.init();
  },

  methods: {
    init() {
      for (var component in BlogPostComponentsEnum) {
        // Add all available components to the selection data
        this.items.push(component);
        // to get the value of the component use: BlogPostComponentsEnum[component]
      }
    },
  },
};
</script>

<style>
</style>